import { FormMode } from "@/consts/form";
import { TimeCardsState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { IRootState } from "@/types/state";
import { TimeCard } from "../types/time_cards";

const { notifySuccess, notifyError } = useNotification();

const defaultTimeCards: TimeCard = {
    id: '',
    employeeId: '',
    expectedCheckIn: new Date(),
    checkIn: new Date(),
    checkOut: new Date(),
    isLate: false,
    createdAt: new Date(),
    createdBy: '',
    updatedAt: new Date(),
    updatedBy: '',
    clientEmployeeScheduleId: ''
};



const state: () => TimeCardsState = () => ({
    time_cards: [],
    showFormDialog: false,
    selectedTimeCard: defaultTimeCards,
    formMode: FormMode.VIEW,
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    }
});

const mutations = {
    SET_TIME_CARDS(state: TimeCardsState, payload: { time_cards: TimeCard[] }) {
        state.time_cards = payload.time_cards;
    },
    SHOW_FORM_DIALOG(state: TimeCardsState, payload: { show: boolean }) {
        state.showFormDialog = payload.show;
    },
    SET_SELECTED_TIME_CARD(state: TimeCardsState, payload: { time_card: TimeCard }) {
        state.selectedTimeCard = Object.assign({}, payload.time_card);
    },
    SET_FORM_MODE(state: TimeCardsState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },

};

const actions = {

    openViewTimeCardDialog({ commit }: ActionContext<TimeCardsState, IRootState>, payload: { time_card: TimeCard }) {
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        commit('SET_SELECTED_TIME_CARD', { time_card: payload.time_card });
        commit('SHOW_FORM_DIALOG', { show: true });
    },

    closeDialog({ commit }: ActionContext<TimeCardsState, IRootState>) {
        commit('SHOW_FORM_DIALOG', { show: false });
    },
    async fetchTimesCards({ commit, state }: ActionContext<TimeCardsState, IRootState>,) {
        try {
            const params: any = {};

            const response = await axiosAuth.get<DefaultResponse<TimeCard>>(`${endPoints.avaliations}`, { params });
            console.log(response.data.data);
            commit('SET_TIME_CARDS', { time_cards: response.data.data });
        } catch (error) {
            notifyError('Erro ao buscar avaliações');
        }
    },





};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};