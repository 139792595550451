import { RouteRecordRaw } from 'vue-router';

const registerPointRoutes : Array<RouteRecordRaw> = [
    {
        path: '/register_point',
        name: 'register_point',
        component: () => import( './views/RegisterPointView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default registerPointRoutes;