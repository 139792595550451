import { RegisterPointState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { Avaliations } from "../types/avaliations";
import { IRootState } from "@/types/state";

const { notifySuccess, notifyError } = useNotification();




const state: () => RegisterPointState = () => ({
    schedulesEmployee: [],
    showTableSchedules: false,
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    locationEmployee: {
        latitude: 0,
        longitude: 0
    }


});

const mutations = {
    SET_SCHEDULES_EMPLOYEE(state: RegisterPointState, payload: { schedulesEmployee: [] }) {
        state.schedulesEmployee = payload.schedulesEmployee;
    },

    SHOW_TABLE_SCHEDULES(state: RegisterPointState, payload: { show: boolean }) {
        state.showTableSchedules = payload.show;
    },

    SET_LOCATION_EMPLOYEE(state: RegisterPointState, payload: { latitude: number, longitude: number }) {
        state.locationEmployee = {
            latitude: payload.latitude,
            longitude: payload.longitude
        };
    }


};

const actions = {

    async getLocation({ commit }: ActionContext<RegisterPointState, IRootState>, payload: { location: string }) {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log("Localização obtida:", latitude, longitude);
                    // Chame uma ação ou mutação do Vuex para enviar a localização ao backend
                    commit("SET_LOCATION_EMPLOYEE", {
                        latitude,
                        longitude,
                    });
                },
                (error) => {
                    console.error("Erro ao obter localização:", error);
                    notifyError("Erro ao obter localização.");
                }
            );
        } else {
            console.error("Geolocalização não é suportada por este navegador.");
        }
    },
   
    async getSchedulesEmployees({ commit }: ActionContext<RegisterPointState, IRootState>, payload: { cpfEmployee: string }) {
        try {
            console.log(payload.cpfEmployee);
            const response = await axiosAuth.get<DefaultResponse<any>>(`${endPoints.client_employee}/schedules/by-employee/${payload.cpfEmployee}`);

            console.log(response.data.data);
            commit('SET_SCHEDULES_EMPLOYEE', { schedulesEmployee: response.data.data });
            commit('SHOW_TABLE_SCHEDULES', { show: true });

        } catch (error) {
            notifyError('Erro ao buscar avaliações dos funcionários');
        }
    }


};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};