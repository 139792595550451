export const units = [
    { text: "Unidade", value: "UN" },
    { text: "Quilograma", value: "KG" },
    { text: "Litro", value: "LT" },
    { text: "Metro", value: "MT" },
]

export enum StockTypes {
    INTERNAL = 0,
    EXTERNAL = 1
}

export enum StockFilterModes {
    POSITION = 0,
    HISTORY = 1
}