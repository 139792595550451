import { RouteRecordRaw } from 'vue-router';

const timeCardsRoutes : Array<RouteRecordRaw> = [
    {
        path: '/time_cards',
        name: 'time_cards',
        component: () => import( './views/TimeCardsView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default timeCardsRoutes;