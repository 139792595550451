import { defaultClient } from "../client/consts";
import { defaultService } from "../services/consts";
import { ClientServices } from "./types/client_services";



export enum TypesServices {
    PAID = 0,
    BONUS = 1
}

export enum Recurrent {
    SIM = 0,
    NAO = 1
}

export enum FormMode {
    Add = "add",
    Edit = "edit",
    View = "view",
}



export const defaultClientServices: ClientServices = {
    id: '',
    type: 0,
    period: 0,
    firstDate: new Date(),
    lastDate: new Date(),
    recurrent: false,
    recurrencePeriod:0,
    createdAt: new Date(),
    createdBy: '',
    updatedAt: null,
    updatedBy: null,
    clientId: '',
    serviceId:'',
    client: defaultClient,
    service: defaultService
}