import { Employee, IFilterEmployee } from "./types/employee";

export enum ClientStatus {
    INACTIVE = 0,
    ACTIVE = 1,
}

export enum Regions {
    NORTE = 0,
    SUL = 1,
    LESTE = 2,
    NORDESTE = 3,
    OESTE = 4,
    NOROESTE = 5,
    CENTRO_SUL = 6,
    BARREIRO = 7,
    PAMPULHA = 8,
    VENDA_NOVA = 9,
    CENTRO = 10,
}

export enum FormMode {
    Add = "add",
    Edit = "edit",
    View = "view",
}

export const defaultEmployee: Employee = {
    doc: '',
    name: '',
    address: '',
    addressCep: '',
    addressCity: '',
    addressNeighborhood: '',
    addressComplement: '',
    addressNumber: '',
    contractedHours: 220,
    createdAt: new Date(),
    isFixed: false,
    isInTrial: false,
    trialEndDate: (() => {
        const date = new Date();
        date.setDate(date.getDate() + 90);
        return date;
    })(),
    createdBy: '',
    phone: '',
    updateAt: null,
    updatedBy: null,
    status: 1,
};

export const defaultFilterEmployee: IFilterEmployee = {
    doc: '',
    name: '',
    phone: '',
    status: null,
    address: '',
    addressCep: '',
    addressCity: '',
    addressNeighborhood: '',
    region: null,
}