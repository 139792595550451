import { RouteRecordRaw } from 'vue-router';

const responsiblesRoutes : Array<RouteRecordRaw> = [
    {
        path: '/responsibles',
        name: 'responsibles',
        component: () => import( './views/ResponsiblesView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default responsiblesRoutes;