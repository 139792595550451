import { FormMode } from "@/consts/form";
import { AvaliationsState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { Avaliations } from "../types/avaliations";
import { IRootState } from "@/types/state";
import { Employee } from "@/modules/employee/types/employee";
import { defaultClient } from "@/modules/client/consts";
import { th } from "vuetify/lib/locale/index.mjs";

const { notifySuccess, notifyError } = useNotification();

const defaultAvaliations: Avaliations = {
    id: '',
    inspectionDate: new Date(),
    roomOrganized: false,
    hasTimeline: false,
    clientPresent: false,
    clientId: '',
    client: defaultClient,
    createdAt: new Date(),
    createdBy: '',
};



const state: () => AvaliationsState = () => ({
    avaliations: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    employeesToClient: [],
    selectedAvaliation: Object.assign({}, defaultAvaliations),
    formMode: FormMode.ADD,
    showFormDialog: false,
    showDeleteDialog: false,
    employeeRatings: {},
    
});

const mutations = {
    SET_AVALIATIONS(state: AvaliationsState, payload: { avaliations: Avaliations[] }) {
        state.avaliations = payload.avaliations;
    },
    SHOW_FORM_DIALOG(state: AvaliationsState, payload: { show: boolean }) {
        state.showFormDialog = payload.show;
    },
    SET_SELECTED_AVALIATION(state: AvaliationsState, payload: { avaliations: Avaliations }) {
        state.selectedAvaliation = Object.assign({}, payload.avaliations);
    },
    SET_FORM_MODE(state: AvaliationsState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },
    SET_EMPLOYEES_TO_CLIENT(state: AvaliationsState, payload: { employees: Employee[] }) {
        state.employeesToClient = payload.employees;
    },
    SET_EMPLOYEE_RATINGS(state: AvaliationsState, payload: { employeeRatings: { [key: string]: number } }) {
        state.employeeRatings = payload.employeeRatings;
    }
};

const actions = {
    openEditAvaliationDialog({ commit }: ActionContext<AvaliationsState, IRootState>, payload: { avaliation: Avaliations }) {
        commit('SET_FORM_MODE', { mode: FormMode.EDIT });
        commit('SET_SELECTED_AVALIATION', { avaliations: payload.avaliation });
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openViewAvaliationDialog({ commit }: ActionContext<AvaliationsState, IRootState>, payload: { avaliation: Avaliations }) {
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        commit('SET_SELECTED_AVALIATION', { avaliations: payload.avaliation });
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openCreateDialog({ commit }: ActionContext<AvaliationsState, IRootState>) {
        commit('SET_FORM_MODE', { mode: FormMode.ADD });
        commit('SET_SELECTED_AVALIATION', { avaliations: defaultAvaliations });
        commit('SHOW_FORM_DIALOG', { show: true });
    },

    closeDialog({ commit }: ActionContext<AvaliationsState, IRootState>) {
        commit('SHOW_FORM_DIALOG', { show: false });
    },
    async fetchAvaliations({ commit, state }: ActionContext<AvaliationsState, IRootState>,) {
        try {
            const params: any = {};

            const response = await axiosAuth.get<DefaultResponse<Avaliations>>(`${endPoints.avaliations}`, { params });
            console.log(response.data.data);
            commit('SET_AVALIATIONS', { avaliations: response.data.data });
        } catch (error) {
            notifyError('Erro ao buscar avaliações');
        }
    },
    async fetchEmployeesByClientId({ commit }: ActionContext<AvaliationsState, IRootState>, payload: { clientId: string }) {
        try {
            const url = `${endPoints.employees}/by-client/${payload.clientId}`;

            const response = await axiosAuth.get<DefaultResponse<Avaliations>>(url);
            commit('SET_EMPLOYEES_TO_CLIENT', { employees: response.data.data });
        } catch (error) {
            notifyError('Erro ao buscar funcionários');
        }
    },

    async saveAvaliation({ state,commit }: ActionContext<AvaliationsState, IRootState>) {
        try {
            let response;
            if (state.formMode === FormMode.ADD) {
                delete state.selectedAvaliation.id;
                delete state.selectedAvaliation.client;
                response = await axiosAuth.post<DefaultResponse<Avaliations>>(endPoints.avaliations, state.selectedAvaliation);
                commit('SET_SELECTED_AVALIATION', { avaliations: response.data });
            } else {
                response = await axiosAuth.put<DefaultResponse<Avaliations>>(`${endPoints.avaliations}/${state.selectedAvaliation.id}`, state.selectedAvaliation);
            }
            notifySuccess('Avaliação salva com sucesso');
        } catch (error) {
            notifyError('Erro ao salvar avaliação');
            throw error;
        }
    },
    async updateAvaliation({ state, commit }: ActionContext<AvaliationsState, IRootState>) {
        try {
            const data = state.selectedAvaliation;
            delete data.client;
            const response = await axiosAuth.put<DefaultResponse<Avaliations>>(`${endPoints.avaliations}/${state.selectedAvaliation.id}`, state.selectedAvaliation);
            commit('SET_SELECTED_AVALIATION', { avaliations: response.data });
        } catch (error) {
            notifyError('Erro ao atualizar avaliação');
        }
    },
    async saveEmployeesAvaliations({ commit, dispatch, state }: ActionContext<AvaliationsState, IRootState>) {
        try {
            const avaliationId = state.selectedAvaliation.id;

            const employeesAvaliations = Object.entries(state.employeeRatings).map(([employeeId, employeeSatisfaction]) => ({
                employeeId,
                employeeSatisfaction,
            }));

            const dataToSend = {
                avaliationId,
                avaliations: employeesAvaliations,
            };

            console.log(dataToSend);

            await axiosAuth.post<DefaultResponse<Avaliations>>(endPoints.employees_avaliations, dataToSend);
            notifySuccess('Avaliação salva com sucesso');
            commit('SHOW_FORM_DIALOG', { show: false });
            dispatch('fetchAvaliations');
        } catch (error) {
            notifyError('Erro ao salvar avaliação');
        }
    },

    async updateEmployeesAvaliations({ commit, dispatch, state }: ActionContext<AvaliationsState, IRootState>) {
        try {
            const avaliationId = state.selectedAvaliation.id;

            const employeesAvaliations = Object.entries(state.employeeRatings).map(([employeeId, employeeSatisfaction]) => ({
                employeeId,
                employeeSatisfaction,
            }));

            const dataToSend = {
                avaliationId,
                avaliations: employeesAvaliations,
            };


            await axiosAuth.put<DefaultResponse<Avaliations>>(endPoints.employees_avaliations, dataToSend);
            notifySuccess('Avaliação atualizada com sucesso');
            commit('SHOW_FORM_DIALOG', { show: false });
            dispatch('fetchAvaliations');
        } catch (error) {
            notifyError('Erro ao atualizar avaliação');
        }
    },

    async getEmployeesAvaliations({ commit, state }: ActionContext<AvaliationsState, IRootState>) {
        try {
            const avaliationId = state.selectedAvaliation.id;
            const response = await axiosAuth.get<DefaultResponse<any>>(`${endPoints.employees_avaliations}/${avaliationId}`);
            const employeeRatings = response.data.data.reduce((acc: any, current: any) => {
                acc[current.employeeId] = current.employeeSatisfaction;
                return acc;
            }, {});
            console.log(employeeRatings);
            commit('SET_EMPLOYEE_RATINGS', { employeeRatings });
        }catch (error) {
            notifyError('Erro ao buscar avaliações dos funcionários');
        }
    }


};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};