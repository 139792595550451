import { RouteRecordRaw } from 'vue-router';

const avaliationsRoutes : Array<RouteRecordRaw> = [
    {
        path: '/avaliations',
        name: 'avaliations',
        component: () => import( './views/AvaliationsView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default avaliationsRoutes;