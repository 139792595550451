import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


createApp(App).use(store).use(router).use(vuetify).use(Toast).mount('#app')
