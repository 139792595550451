<template>
    <div class="text-left">
        <v-list-group
            :prepend-icon="menu.icon"
            no-action
            :value="menu.id"
        >
            <template v-slot:activator="{ props }">
                <v-list-item
                    v-bind="props"
                    :prepend-icon="menu.icon"
                >
                    {{ menu.description }}
                </v-list-item>
            </template>

            <v-list-group-value>
                <div
                    v-for="subMenu in menu.menus"
                    :key="subMenu.id"
                >
                    <simple-menu :menu="subMenu"></simple-menu>
                </div>
            </v-list-group-value>

            
        </v-list-group>
    </div>
</template>

<script>

import { mapActions } from 'vuex'
import SimpleMenu from './SimpleMenu'

export default {
    name: "CompoundMenuItem",
    components: {
        "simple-menu": SimpleMenu
    },
    methods: {   
        ...mapActions('menu', ['openMenu']),
    },
    props: [
        'menu'
    ]
};
</script>

<style>
  .menu-line {
      display: inline;
  }
</style>